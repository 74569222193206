import { appType, AppType } from "../helper";
import { global, type NativeAppEvent, type RetrieveNativeAppEvents } from "./index";
import type { FunctionImplementation } from "@otto-ec/global-resources/nexus";

export const retrieveNativeAppEvents = function retrieveNativeAppEvents() {
    const fallbackValue: NativeAppEvent[] = []

    if(appType() == AppType.ANDROID) {
        const events = window?.o_apps_android?.retrieveNativeAppEvents?.()
        
        if (!events) return fallbackValue

        return JSON.parse(events as string).map((event: NativeAppEvent) => ({
            ...event,
            timestamp: new Date(event.timestamp)
        }));
        
    } else if(appType() == AppType.IOS) {
        return window?.webkit?.messageHandlers?.retrieveNativeAppEvents?.postMessage({}) ?? fallbackValue
    }
    return fallbackValue;
} satisfies FunctionImplementation<RetrieveNativeAppEvents>

global.assignNamespace({retrieveNativeAppEvents})